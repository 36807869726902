import Vue from "vue";
import Vuex from "vuex";
import {auth} from "@/store/auth";
import {diensten} from "@/store/diensten";
import {dienst} from "@/store/dienst";

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth,
        diensten,
        dienst,
    }
})