<template>
  <v-ons-navigator
      :pageStack="pageStack"
      @postpop="clearChatsInterval"
      swipeable
  >
  </v-ons-navigator>
</template>

<script>
import Dienst from "@/views/Dienst";

export default {
  name: 'DienstStack',
  data() {
    return {
      pageStack: [Dienst]
    }
  },
  mounted() {
  },
  created() {

    this.$eventListener.$on('push-page', child => {
      this.pageStack.push(child);
    });
    this.$eventListener.$on('pop-page', () => {
      this.pageStack.pop();
    });
  },
  methods: {
    clearChatsInterval(){
      this.$eventListener.$emit('clear-chat-intervals');
    }
  }
}
</script>

<style lang="scss">
</style>
