import {api} from "@/setup/api";

const defaultState = {
    items: JSON.parse(localStorage.getItem('diensten')) || [],
};

export const diensten = {
    namespaced: true,
    state: defaultState,
    actions: {
        getDiensten({commit, rootState}) {

            const config = {
                params: {
                    api_key: process.env.VUE_APP_API_KEY,
                    auth_key: rootState.auth.auth_key,
                    user_id: rootState.auth.user_id,
                }
            };
            
            return new Promise((resolve, reject) => {
                api.get('/1.0/diensten.overzicht.json', config)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        const diensten = responseData.result;
                        commit('setDiensten', diensten);

                        const firstSaasIdValue = diensten[Object.keys(diensten)[0]].saas_id;                        
                        resolve({numberOfDiensten: Object.keys(diensten).length, firstSaasId: firstSaasIdValue});
                    })
                    .catch(reject);
            });
        }
    },
    
    getters: {
        dienstItem: (state) => (saas_id) => {
            for (const element in state.items) {
                const item = state.items[element];
                if(item.saas_id === saas_id) return item;
            }
        },
    },
    mutations: {
        setDiensten(state, diensten) {
            localStorage.setItem('diensten', JSON.stringify(diensten));
            state.items = diensten;
        },
    },
}