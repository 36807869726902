<template>
  <v-ons-page>
    <toolbar-default title="Diensten" :toggleMenuAction="toggleMenu" class="diensten-header" v-bind:class="{ show: !isLoading }"></toolbar-default>
    <page-loader v-if="isLoading"></page-loader>
    
    <v-ons-list>
      <v-ons-list-item v-for="(dienst, index) in diensten" v-bind:key="index" tappable modifier="chevron" @click="goToApplication(dienst,false)">        
        <div class="left" v-if="dienst.overview_notifications > 0">
          <span class="notification_counter">{{dienst.overview_notifications}}</span>
        </div>
        <div class="center">{{dienst.title}}</div>
      </v-ons-list-item>
    </v-ons-list>
    
  </v-ons-page>
</template>

<script>
import {mapActions} from "vuex";
import ToolbarDefault from "@/components/ToolbarDefault";
import PageLoader from '@/components/PageLoader.vue';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';

export default {
  name: 'Diensten',
  props: [
    'toggleMenu',    
  ],
  components: { 
    ToolbarDefault,
    PageLoader,
  },
  data: function () {
    return {
      isLoading: true
    }
  },
  methods: {
    ...mapActions('diensten', {
      'getDiensten': 'getDiensten'
    }),
    goToApplication(dienst, forceRedirect){
      if(forceRedirect){
        const saas_id = dienst;
        this.$router.push({ path: '/dienst/' + saas_id        , query: { is_customers_only_dienst: 'true'}});
      } else {
        this.$router.push({ path: '/dienst/' + dienst.saas_id });
      }
    }
  },
  mounted() {
    const _self = this;


    // Reload diensten if state is changes
    App.addListener('appStateChange', async function (state) {
        _self.isLoading = true;
        const networkStatus = await Network.getStatus();
        if (state.isActive && networkStatus.connected) {
           _self.getDiensten().then((response)=>{
              _self.isLoading = false;
             if(response.numberOfDiensten === 1) _self.goToApplication(response.firstSaasId, true);
            })
            .catch(error => console.error('getDiensten', error))
        }
    });
  },
  beforeMount() {
    this.getDiensten().then((response)=>{
          this.isLoading = false;
          if(response.numberOfDiensten === 1) this.goToApplication(response.firstSaasId, true);
        })
        .catch(error => console.error('getDiensten', error))
  },
  computed: {
    diensten() {
      return this.$store.state.diensten.items;
    },
  }
}
</script>
<style lang="scss">
#toolbar-default.diensten-header {
  display: none;

  &.show {
    display: flex;
  }
}

.notification_counter {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background:$yb_colorYellow;
  color: $yb_colorBlack;
}
</style>