<template>
  <v-ons-page id="page-login">
    <div id="header" v-bind:class="{show: !showForm}">
      <div class="content">
        <div class="icon"><img src="@/assets/img/yb-logo.png" alt="Logo" /></div>
        <div class="title">Chat</div>
        
        <button type="button" class="form-button" v-on:click="toggleLoginForm()">
          Inloggen
        </button>
      </div>
    </div>
    <div>
    <div id="form" v-bind:class="{show: showForm}">
      <div class="form-group">
        <div class="label">E-mailadres</div>
        <input type="email" class="form-input" v-model="formContent.username" >
      </div>
      <div class="form-group">
        <div class="label">Wachtwoord</div>
        <input type="password" class="form-input" v-model="formContent.password" >
      </div>
      <div class="form-group form-submit">
        <button type="button" class="form-button" v-on:click="attemptLogin()">
          <span v-if="!isLoggingIn">Inloggen</span>
          <span v-if="isLoggingIn">
            <ons-icon icon="fa-spinner" spin></ons-icon>
          </span>
        </button>
      </div>
      <div class="form-group form-error text-center">
        {{ formContent.formError }}
      </div>
    </div>
    </div>
  </v-ons-page>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {Capacitor} from "@capacitor/core";
import {store} from "@/store";
import { PushNotifications } from '@capacitor/push-notifications';

export default {
  name: 'Login',
  data() {
    return {
      formContent: {
        username: '',
        password: '',
        formError: '',
      },
      isLoggingIn: false,
      showForm: false,
    }
  },
  methods: {
    ...mapActions('auth', {
      'login': 'login',
    }),
    toggleLoginForm(){
      this.showForm = !this.showForm;
    },
    attemptLogin() {
      if (this.isLoggingIn) return;

      this.formContent.formError = '';
      if (this.formContent.username === '' || this.formContent.password === '') {
        this.formContent.formError = 'E-mailadres/wachtwoord mag niet leeg zijn!';
      }

      // If no error continue login
      if (this.formContent.formError === '') {
        this.isLoggingIn = true;
        this.login({username: this.formContent.username, password: this.formContent.password})
            .then(() => {
              this.isLoggingIn = false;

              if(Capacitor.isNativePlatform()){
                
                // Register device
                PushNotifications.requestPermissions().then(result => {
                    if (result.receive === 'granted'){

                      // Register device push token to API
                      PushNotifications.addListener('registration', (token) => {
                              if(token.value !== undefined){
                                  store.dispatch('auth/deviceRegisterPushToken', token.value)
                                    .catch(error => console.error('deviceRegisterPushToken: ', error.data));
                              }
                          }
                      );

                      PushNotifications.register();
                    }
                });

                
              }
              
                this.$router.push({ path: '/' });
            })
            .catch(() => {
              this.formContent.formError = 'E-mailadres of wachtwoord is incorrect!';
              this.isLoggingIn = false;
            });
      }

    }
  },
}
</script>

<style lang="scss">
#page-login {
  height: 100vh;
  overflow: hidden;

  #header {
    position: relative;
    height: 30vh;
    background: $yb_colorGrey;
    transition: height .75s ease-in-out;

    &.show {
      height: 100vh;

      .content {
        transform: translateX(-50%) translateY(-100%);
        
        .form-button {
          display: block;
          position: absolute;
          bottom: -10rem;
        }
      }
    }

    .content {
      transition: top .75s ease-in-out, transform .75s ease-in-out;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;

      .title {
        color: $yb_colorBlack;
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-transform: uppercase;
        margin-top: 1rem;
      }

      .form-button {
        display: none;
      }
    }
  }

  #form {
    overflow: hidden;
    padding: 0;
    background: white;
    height: 0px;
    transition: height .75s ease-in-out;

    &.show {
      padding: 2rem 3rem;
      height: calc(70vh - 4rem);
    }

  }
}
</style>
