import axios from 'axios';

const defaultState = {
    apiUrl: 'https://application-SAAS_ID.app.yelloobox.com/webservice/core/tools/yelloobox-chat/app',
    // apiUrl: 'https://chat.saas.yelloobox.com/webservice/core/tools/yelloobox-chat/app',
    chats: [],
    activeChatMessages: [],
    activeChat: [],
};

export const dienst = {
    namespaced: true,
    state: defaultState,
    actions: {
        getChats({commit, state, rootState}, saas_id) {

            const config = {
                params: {
                    api_key: process.env.VUE_APP_API_KEY,
                    auth_key: rootState.auth.auth_key,
                    user_id: rootState.auth.user_id,
                }
            };

            const SAAS_API_URL = state.apiUrl.replace('SAAS_ID', saas_id.toString());

            return new Promise((resolve, reject) => {
                axios.get(SAAS_API_URL + '/1.0/dienst.chats.json', config)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        commit('setChats', responseData.result);
                        resolve();
                    })
                    .catch(reject);
            });
        },
        getChatMessages({commit, state, rootState}, {chat_id, saas_id}) {

            const config = {
                params: {
                    api_key: process.env.VUE_APP_API_KEY,
                    auth_key: rootState.auth.auth_key,
                    user_id: rootState.auth.user_id,
                    chat_id: chat_id,
                }
            };
            
            const SAAS_API_URL = state.apiUrl.replace('SAAS_ID', saas_id.toString());
            
            return new Promise((resolve, reject) => {
                axios.get(SAAS_API_URL + '/1.0/dienst.chat.messages.json', config)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        commit('setActiveChatMessages', responseData.result.messages);
                        commit('setActiveChat', responseData.result);
                        resolve();
                    })
                    .catch(reject);
            });
        },
        sendMessage({commit, state, rootState}, {chat_id, saas_id, message}) {

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('auth_key', rootState.auth.auth_key);
            formData.append('user_id', rootState.auth.user_id);
            formData.append('chat_id', chat_id);
            formData.append('message', message);
            
            const SAAS_API_URL = state.apiUrl.replace('SAAS_ID', saas_id.toString());
            
            return new Promise((resolve, reject) => {
                if(message == '') reject('Message is empty');
                axios.post(SAAS_API_URL + '/1.0/dienst.chat.messages.add.json', formData)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        console.log(responseData);
                        resolve();
                    })
                    .catch(reject);
            });
        },
        subscribeMemberToChat({commit, state, rootState}, {chat_id, saas_id}) {

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('auth_key', rootState.auth.auth_key);
            formData.append('user_id', rootState.auth.user_id);
            formData.append('chat_id', chat_id);

            const SAAS_API_URL = state.apiUrl.replace('SAAS_ID', saas_id.toString());
            
            return new Promise((resolve, reject) => {
                axios.post(SAAS_API_URL + '/1.0/dienst.chat.members.add.json', formData)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        resolve();
                    })
                    .catch(reject);
            });
        },
        closeChat({commit, state, rootState}, {chat_id, saas_id}) {

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('auth_key', rootState.auth.auth_key);
            formData.append('user_id', rootState.auth.user_id);
            formData.append('chat_id', chat_id);

            const SAAS_API_URL = state.apiUrl.replace('SAAS_ID', saas_id.toString());
            
            return new Promise((resolve, reject) => {
                axios.post(SAAS_API_URL + '/1.0/dienst.chat.end.json', formData)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        console.log(responseData);
                        if(responseData.success === true) resolve();
                        reject();
                    })
                    .catch(reject);
            });
        },
    },    
    getters: {
        chatItem: (state) => (chat_id) => {
            return state.chats.find(chat => chat.chat_id === chat_id)
        },
        activeChatMessages: (state) => {
            return state.activeChatMessages;
        },
    },
    mutations: {
        setChats(state, chats) {
            localStorage.setItem('chats', JSON.stringify(chats));
            state.chats = chats;
        },
        setActiveChatMessages(state, messages) {
            state.activeChatMessages = messages;
        },
        clearActiveChatMessages(state) {
            state.activeChatMessages = [];
        },
        setActiveChat(state, chat) {
            state.activeChat = chat;
        },
        clearActiveChat(state) {
            state.activeChat = [];
        },
    },
}