<template>
  <v-ons-page>
    <toolbar-default title="Instellingen" :toggleMenuAction="toggleMenu"></toolbar-default>
    <p style="text-align: center">
      Hi instellingen
    </p>
  </v-ons-page>
</template>

<script>
import ToolbarDefault from "@/components/ToolbarDefault";

export default {
  name: 'Instellingen',
  props: ['toggleMenu'],
  components: { ToolbarDefault }
}
</script>