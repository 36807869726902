import {api} from "@/setup/api";
import {PermissionState } from '@capacitor/core';
import {Device} from '@capacitor/device';

const defaultState = {
    user_id: localStorage.getItem('user_id') || false,
    auth_key: localStorage.getItem('auth_key') || false,
    userData: JSON.parse(localStorage.getItem('userData')) || [],
};

export const auth = {
    namespaced: true,
    state: defaultState,
    actions: {
        init({dispatch}) {
            return new Promise((resolve, reject) => {
                Promise.all([dispatch('deviceRegister'), dispatch('deviceRegisterPushToken')]).then(resolve).catch(reject);
            });
        },
        checkLogin({state, dispatch}) {
            const config = {
                params: {
                    api_key: process.env.VUE_APP_API_KEY,
                    auth_key: state.auth_key,
                    user_id: state.user_id,
                }
            };

            return new Promise((resolve, reject) => {
                api.get('/1.0/auth.usercheck.json', config)
                    .then(response => {
                        const responseData = response.data;
                        if(responseData.status === 401) reject();
                        resolve();
                    })
                    .catch(reject);
            });
        },
        login({commit, dispatch}, {username, password}) {

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('username', username);
            formData.append('password', password);

            return new Promise((resolve, reject) => {
                api.post('/1.0/auth.login.json', formData)
                    .then(response => {
                        const responseData = response.data;
                        if (responseData.status === 200) {

                            localStorage.setItem('auth_key', responseData.result.code);
                            localStorage.setItem('user_id', responseData.result.saas_user_id);
                            localStorage.setItem('userData', JSON.stringify(responseData.result.user));
                            commit("setAuthentication", responseData.result);

                            dispatch('init').then(resolve).catch(reject);

                        } else {
                            reject(responseData.result);
                        }
                    })
                    .catch(() => {
                        reject('Er is iets fout gegaan! probeer het nogmaals.');
                    });
            });
        },
        logout({commit}) {
            localStorage.clear();
            return new Promise((resolve) => {
                commit('resetState');
                resolve();
            });
        },
        async deviceRegister({state}) {
            
            const deviceInfo = await Device.getInfo();
            const deviceId      = await Device.getId();

            deviceInfo.uuid = deviceId.uuid;
            deviceInfo.testDevice = false;

            // Add check if is test/debug device
            if(!window.Capacitor.isNative || window.Capacitor.DEBUG === true){
                deviceInfo.testDevice = true;
            }

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('auth_key', state.auth_key);
            formData.append('user_id', state.user_id);
            formData.append('device_info', JSON.stringify(deviceInfo));
            
            return new Promise((resolve, reject) => {
                api.post('/1.0/device.register.json', formData)
                .then(response => {
                    const responseData = response.data;
                    if (responseData.status === 200) {
                        resolve();
                    } else {
                        reject(responseData.result);
                    }
                }).catch(reject);
            });

        },
        async deviceRegisterPushToken({state}, token) {

            const deviceInfo    = await Device.getInfo();
            const deviceId      = await Device.getId();

            deviceInfo.uuid = deviceId.uuid;

            let formData = new FormData();
            formData.append('api_key', process.env.VUE_APP_API_KEY);
            formData.append('auth_key', state.auth_key);
            formData.append('user_id', state.user_id);
            formData.append('device_info', JSON.stringify(deviceInfo));
            formData.append('push_token', token);

            return new Promise((resolve, reject) => {
                api.post('/1.0/device.register.pushtoken.json', formData)
                .then(response => {
                    const responseData = response.data;
                    if (responseData.status === 200) {
                        resolve();
                    } else {
                        reject(responseData.result);
                    }
                }).catch(reject);
            });

        }
    },
    getters: {},
    mutations: {
        setAuthentication(state, loginResponse) {
            state.auth_key = loginResponse.code;
            state.user_id = loginResponse.saas_user_id;
            state.userData = loginResponse.user;
        },
        resetState(state) {
            state = defaultState;
        }
    },
}