import Vue from 'vue';
import moment from 'moment';


// Remove HTML-tags from string
Vue.filter('strip_html', function (value) {
    let div = document.createElement("div");
    div.innerHTML = value;
    let text = div.textContent || div.innerText || "";
    return text;
});

// Limit length of string
Vue.filter('wrap_content', function (value, size) {
    if (!value) return '';
    value = value.toString();
    if (value.length <= size) return value;

    if (window.innerHeight <= 763) size = Math.round(size / 2);

    return value.substr(0, size).trim() + '...';
});

// Format date to NL standard
Vue.filter('formatDateNL', function (value) {
    if (value) {
        let stringData = moment(String(value)).format('DD-MM-YYYY H:mm')
        if(stringData.endsWith('0:00')){
            stringData = stringData.replace(' 0:00','');
        }
        return stringData;
    }
});