import Vue from 'vue'
import Vuex, {mapActions} from 'vuex'
import {api} from "@/setup/api";
import AppRoot from '@/App.vue'
import VueOnsen from 'vue-onsenui';
import router from '@/router'
import {store} from "@/store";
import { Capacitor } from '@capacitor/core';

import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { PushNotifications } from '@capacitor/push-notifications';
import { Network } from '@capacitor/network';
import { StatusBar } from '@capacitor/status-bar';

// Webpack CSS import
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import '@/setup/filters';

VueOnsen.disableIconAutoPrefix();
VueOnsen.disableAutoStyling()
VueOnsen.platform.select('ios');

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.use(Vuex);
Vue.use(VueOnsen);

Vue.prototype.$api = api;
Vue.prototype.$store = store;
Vue.prototype.$eventListener = new Vue();

// Set defaults
if (Capacitor.isNativePlatform()) {
    document.documentElement.classList.add(Capacitor.getPlatform());

    if(Capacitor.getPlatform() === 'ios') {
        if (VueOnsen.platform.isIPhoneX() || VueOnsen.platform.isIPad() ) {
            document.documentElement.setAttribute('onsflag-iphonex-portrait', ``);
            document.documentElement.setAttribute('onsflag-iphonex-landscape', ``);
            document.documentElement.classList.add('iphonex');
        }
    
        Keyboard.setAccessoryBarVisible({isVisible: true});
    }

    if(Capacitor.getPlatform() === 'android') {
        StatusBar.setBackgroundColor({color: '#f7c324'});
        StatusBar.setOverlaysWebView({
            overlay: false
        });
    }

}

// Reload if app returns from background
App.addListener('appStateChange', async function (state) {
    if(!Capacitor.isNativePlatform()) return;
    const networkStatus = await Network.getStatus();
    if (state.isActive && networkStatus.connected) {

        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted'){
                PushNotifications.removeAllDeliveredNotifications().catch(error => console.error('removeAllDeliveredNotifications',error));
            }
        });

        // store.dispatch('STOREMODULE/init').catch(error => console.error('statechange', error))
    }
});

App.addListener('appUrlOpen', (data) => {
    console.error("appUrlOpen", data);
});

// Initialize application
new Vue({
    router,
    store,
    render: h => h(AppRoot),
    mounted() {
        // Initialize all functions/stores
        this.checkLogin().catch(() => {
            this.logout().then(() => {
                // this.$router.push('/login');
            });
        }).then(() => {
            this.initAuth();
            this.initializePushNotifications();
        });

    },
    methods: {
        ...mapActions('auth', {
            'initAuth': 'init',
            'logout': 'logout',
            'checkLogin': 'checkLogin'
        }),
        initializePushNotifications() {
            if (!Capacitor.isNativePlatform()) return;

            
            // Register device
            PushNotifications.requestPermissions().then(result => {
                // console.debug('PushNotifications.requestPermissions', result);
                if (result.receive === 'granted'){

                    // Register device push token to API
                    PushNotifications.addListener('registration', (token) => {

                            PushNotifications.removeAllDeliveredNotifications().catch(error => console.error('removeAllDeliveredNotifications after registration',error));
                            // console.debug('PushNotifications.addListener == registration', token);
                            if(token.value !== undefined){
                                store.dispatch('auth/deviceRegisterPushToken', token.value)
                                .catch(error => console.error('deviceRegisterPushToken: ', error.data));

                                
                            }
                        }
                    );

                    // Check for errors
                    PushNotifications.addListener('registrationError', (error) => {
                            // console.debug('PushNotifications.addListener == registrationError', error);
                            console.error('Error on registration: ', error);
                        }
                    );

                    // Show notification if the app is open on device
                    PushNotifications.addListener('pushNotificationReceived', (notification) => {
                            // console.debug('PushNotifications.addListener == pushNotificationReceived', notification);
                            // console.debug('pushNotificationReceived: ', JSON.stringify(notification));
                        }
                    );

                    // When tapping on a notification
                    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                            // console.debug('PushNotifications.addListener == pushNotificationActionPerformed', notification);

                            if (notification.notification.data) {
                                if (notification.notification.data.saas_id) {
                                    this.$router.push({ path: '/dienst/' + notification.notification.data.saas_id });
                                    PushNotifications.removeAllDeliveredNotifications().catch(error => console.error('removeAllDeliveredNotifications after push action performed',error));
                                }
                            }
                        }
                    );



                    PushNotifications.register();
                }
            });
        }
    },
}).$mount('#app')
