<template>
  <v-ons-splitter>
    <v-ons-splitter-side
      swipeable width="80%" collapse="" side="right"
      :open.sync="openSide"
    >
      <v-ons-page id="sidemenu">
        <side-menu-user-card></side-menu-user-card>
        <v-ons-list class="menu">
          <v-ons-list-item v-for="page in pages" v-bind:key="page" tappable modifier="chevron" @click="currentPage = page; openSide = false">
            <div class="center">{{ page }}</div>
          </v-ons-list-item>
          <v-ons-list-item class="logout" tappable modifier="chevron" @click="logout()">
            <div class="center">Uitloggen</div>
          </v-ons-list-item>
        </v-ons-list>
      </v-ons-page>
    </v-ons-splitter-side>

    <v-ons-splitter-content>
      <component :is="currentPage" :toggle-menu="() => openSide = !openSide"></component>
    </v-ons-splitter-content>
  </v-ons-splitter>
</template>

<script>
import {mapActions} from "vuex";
import Instellingen from "@/views/Instellingen";
import Diensten from "@/views/Diensten";
import SideMenuUserCard from '@/components/SideMenuUserCard';

import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';

export default {
  name: 'Root',
  components: {
    Diensten,
    Instellingen,
    SideMenuUserCard,
  },
  data() {
    return {
      currentPage: 'diensten',
      pages: ['Diensten', 'Instellingen'],
      openSide: false
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('auth', {
      'logoutUser': 'logout'
    }),
    async logout(){
      let confirmRet = await Dialog.confirm({
        title: 'Uitloggen',
        message: 'Weet u zeker dat u wilt uitloggen?',
        PromptOptions: {
          okButtonTitle: "Uitloggen",
          cancelButtonTitle: "Annuleren",
        }
      });

      if (confirmRet.value) {
        this.logoutUser()
            .then(() => {
              this.$router.push('/login');

              Toast.show({
                text: 'U bent succesvol uitgelogd!',
                position: 'bottom',
              }).catch(error => console.error('logoutUser', error));
            });
      }
    }
  }
}
</script>

<style lang="scss">
#sidemenu {

  .menu {
    .logout {
      position: absolute;
      bottom: calc(1px + env(safe-area-inset-bottom));
      background: #FFF;
      color: $colorError;

      .center {
        background-image: none !important;
      }
    }
  }

}
</style>
