<template>  
  <v-ons-page>
    <toolbar-default :title="chat.chat_title" :show-back="true" :backButtonAction="pop" :show-menu="false"></toolbar-default>
    <page-loader v-if="isLoading"></page-loader>
    <div id="yb-chatbox">
      <div class="yb-chatbox-content-messages">
          <chat-message v-for="(message, index) in activeChat.messages" v-bind:key="index" :message="message"></chat-message>
      </div>
      <div class="yb-chatbox-content-form" v-if="activeChat.chat && activeChat.chat.status !== 'finished'">
        <div class="yb-chat-inprogress" v-if="subscribed">
          <textarea name="message" v-model="message" placeholder="Type hier uw bericht"></textarea>
          <button @click="sendMessageToChat()">Verstuur</button>
        </div>
        <div class="chat-options-buttons" v-if="subscribed">
          <button class="end-conversation icon-red-hover" title="Chat sluiten" @click="attemptCloseChat()"><i class="fa fa-sign-out" aria-hidden="true"></i></button>
        </div>
        <div class="yb-chat-subscribe" v-if="!subscribed">
          <button @click="subscribeToChat()">Deelnemen aan dit gesprek</button>
        </div>
      </div>
      
		<div id="confirm-end-modal">
			<div class="modal">
				<p>Weet je zeker dat je het gesprek wilt sluiten?</p>
				<div class="button-wrap">
					<button class="no">Nee</button>
					<button class="yes">Ja</button>
				</div>
			</div>
		</div>

    </div>
  </v-ons-page>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import ToolbarDefault from "@/components/ToolbarDefault";
import PageLoader from '@/components/PageLoader.vue';

import ChatMessage from '@/components/ChatMessage';

import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';

export default {
  name: 'DienstChat',
  props: ['chat_id','saas_id'],
  components: { 
    ToolbarDefault,
    PageLoader,
    ChatMessage,
  },
  data: function () {
    return {
      isLoading: true,
      chat: [],
      intervalFetcher: false,
      numberOfMessages: 0,
      subscribed: false,
      message: '',
    }
  },
   computed: {
    ...mapGetters('dienst', [
      'chatItem',
    ]),
    activeChat() {
      return this.$store.state.dienst.activeChat;
    },
    userId() {
      return this.$store.state.auth.userId;
    },
  },
  mounted() {
    const showErrorToast = async () => {
      await Toast.show({
        text: 'Oeps, er is iets fout gegaan!',
      });
    };

    try {
      this.chat = this.chatItem(this.chat_id);
      if (this.chat && this.chat.chat_id) {
        this.checkSubscription();
        this.fetchMessages();
        this.isLoading= false;

        this.$eventListener.$on('clear-chat-intervals', () => {
          clearInterval(this.intervalFetcher);
          this.clearActiveChat();
        });

      } else {
        showErrorToast();
      }
    } catch (error) {
      showErrorToast();
    }
  },
  methods: {
    ...mapActions('dienst', [
      'getChatMessages',
      'subscribeMemberToChat',
      'sendMessage',
      'closeChat',
    ]),
    ...mapMutations('dienst', [
      'clearActiveChat',
    ]),
    checkSubscription(){
      for (const memberIndex in this.chat.members) {
        const member = this.chat.members[memberIndex];
        if(member.type === "user" && member.user_key === localStorage.getItem('user_id')){
              this.subscribed = true;
        }
      }
    },
    pop() {
      this.$eventListener.$emit('pop-page');
    },
    subscribeToChat(){
      this.subscribeMemberToChat({ chat_id: this.chat_id, saas_id: this.saas_id})
            .then(()=>{
              this.subscribed = true;
            });
    },
    fetchMessages(){  
        let self = this;          
        this.numberOfMessages = self.$el.getElementsByClassName("yb-chatbox-message").length;
        this.intervalFetcher = setInterval(function(){
            self.getChatMessages({ chat_id: self.chat_id, saas_id: self.saas_id})
            .then(()=>{
              var newNumberOfMessages = self.$el.getElementsByClassName("yb-chatbox-message").length;

              if(newNumberOfMessages > self.numberOfMessages){              
                self.numberOfMessages = newNumberOfMessages;
                let messageContainer = self.$el.querySelector("#yb-chatbox .yb-chatbox-content-messages");
                messageContainer.scrollTop = messageContainer.scrollHeight;
              }
            });
        }, 1000);
    },
    sendMessageToChat(){  
      this.sendMessage({ chat_id: this.chat_id, saas_id: this.saas_id, message: this.message})
            .then(()=>{
              this.message = '';
            });
    },
    async attemptCloseChat(){
      let confirmRet = await Dialog.confirm({
        title: 'Chat beeïndigen',
        message: 'Weet u zeker dat u deze chat wilt beeïndigen?',
        PromptOptions: {
          okButtonTitle: "Beeïndigen",
          cancelButtonTitle: "Annuleren",
        }
      });

      if (confirmRet.value) {
        this.closeChat({ chat_id: this.chat_id, saas_id: this.saas_id})
          .then(this.pop());          
      }
    }
  },
}
</script>

<style lang="scss">

#yb-chatbox {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  

  
  .yb-chatbox-content-messages {
    flex-grow: 1;
    padding: 10px;
    font-family:  Helvetica, Arial, "sans-serif";
    overflow-x: auto;
    
    .yb-chatbox-message {
      margin-bottom: .5rem;
      
      
      &.yb-chatbox-send-by-creator,
      &.yb-chatbox-send-by-user {
        
        .yb-chatbox-message-sender {
          color: darken($yb_grey, 30%);
          font-size: .8rem;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .yb-chatbox-message-content {
          background: $yb_white;
          color: $yb_black;
          border: 1px solid lighten($yb_grey, 20%);
          padding: 8px 15px;
          display: inline-block;
          border-radius: 10px;
          max-width: 75%;
          text-align: left;
          position: relative;
        }
        
        .yb-chatbox-message-seen {
          position: absolute;
          background: $yb_blue;
          color: white;
          padding: 0 3px;
          border-radius: 50%;
          font-size: .5rem;
          right: -5px;
          bottom: -5px;
        }
      }
      
      &.yb-chatbox-send-by-system {
        background: none;
        text-align: center;
        color: $yb_grey;
        opacity: .7;
        
        .yb-chatbox-message-sender {
          display: none;
        }
        .yb-chatbox-message-content {
          background: none;
        }
      }
      
      
      &.yb-chatbox-send-by-user {
        text-align: right;
      }
      
    }
    
  }

  .yb-chatbox-content-form {
    background: $yb_colorWhite;
    padding: 10px;
    padding-bottom: 5px;
    border-top: 1px solid $yb_colorYellow;
    
    .yb-chat-inprogress {
      display: flex;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $yb_colorGrey;
      
      textarea {
        flex-grow: 1;
        border: 1px solid transparent;
        resize: none;	
        font-family:  Helvetica, Arial, "sans-serif";
          line-height: 1.5rem;
          
        &:focus {
          outline: none;
        }
      }
      
      button {
        border-radius: 5px;
        cursor: pointer;
        background: $yb_colorBlack;
        color: $yb_colorWhite;
        border: none;
        padding: 5px 10px;
        transition: background .3s ease;

        &:hover {
          background: $yb_colorAccent;
        }
      }
      
    }
    .chat-options-buttons {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				
				button {
					background: none;
					color: $yb_grey;
					border: none;
					cursor: pointer;
					
					&.icon-red-hover {
						&:hover,
						&:focus {
							color: $yb_red;
						}
					}
					
					
					&:hover,
					&:focus {
						color: $yb_black;
						cursor: pointer;
					}
				}
			}

    .yb-chat-subscribe {
      
      button {
        border-radius: 5px;
        cursor: pointer;
        background: $yb_colorBlack;
        color: $yb_colorWhite;
        border: none;
        padding: 15px 10px;
        transition: background .3s ease;
        width: 100%;

        &:hover {
          background: $yb_colorAccent;
        }
      }
    }

    
  }
}


	
	#confirm-end-modal {
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgba(0, 0, 0, 0.6);
		
		&.active {
			display: block;
		}
		
		.modal {
			width: 240px;
			height: 120px;
			background: white;
			padding: 5px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 auto;
			left: 0;
			right :0;
			box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
			border-radius: 15px;

			p {
				text-align: center;
				font-weight: 700;
			}

			.button-wrap {
				display: flex;
				justify-content: space-around;

				button {
					padding: 10px 15px;
					border-radius: 10px;
					border: none;
					background: black;
					color: white;
					
					&:hover,
					&:focus {
						cursor: pointer;
					}
				}
			}
		}
	}

</style>
