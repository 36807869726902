import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});


function logAxiosResponse(response){
    if(!window.Capacitor.isNative || window.Capacitor.DEBUG === true){
        console.log('*********** START ***********');
        // console.log(response.config.url);
        console.log(response.config.url, JSON.stringify(response.data, null, 2));
        console.log('*********** EINDE ***********');
    }
}

axios.interceptors.response.use(response => {
    logAxiosResponse(response);
    return response;
});


api.interceptors.response.use(response => {
    logAxiosResponse(response);
    return response;
});
