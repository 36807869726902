<template>
  <div id="side-menu-user-card">
    Hi {{userData.user_firstname}}
  </div>
</template>

<script>
export default {
  name: 'ToolbarDefault',
  computed: {
    userData() {
      return this.$store.state.auth.userData;
    },
  },
}
</script>
<style lang="scss">
#side-menu-user-card { 
    display: flex;
    min-height: 44px;
    align-items: center;
    padding-left: 14px;
}
</style>
