<template>
  <v-ons-page>
    <toolbar-default :title="this.item.title" :show-back="!isCustomersOnlyDienst" :show-menu="false"></toolbar-default>
    <page-loader v-if="isLoading"></page-loader>
    
    <v-ons-list-title>Actieve chats</v-ons-list-title>
    <v-ons-list class="chats">
      <v-ons-list-item class="chat" v-for="(chat, index) in activeChats" v-bind:key="index" tappable @click="openChat(chat)">
        <div class="left">
          <ons-icon class="message_icon new"  v-if="chat.has_new_message" icon="ion-ios-mail-unread, material:md-mail-unread"></ons-icon>
          <ons-icon class="message_icon"      v-if="!chat.has_new_message" icon="ion-ios-chatbubbles, material:md-chatbubbles"></ons-icon>
        </div>
        <div class="center">
          <div class="title" v-html="chat.chat_title"></div>
          <div class="title_status" v-html="chat.status_title"></div>
        </div>
        <div class="right">
          <div class="update_date" v-html="chat.datetime_updated_string"></div>
        </div>
      </v-ons-list-item>
      <v-ons-list-item v-if="activeChats.length == 0">
        <div class="left">Geen resultaten</div>
      </v-ons-list-item>
    </v-ons-list>

    <br>
    
    <v-ons-list-title>Gesloten chats</v-ons-list-title>
    <v-ons-list class="chats">
      <v-ons-list-item class="chat" v-for="(chat, index) in closedChats" v-bind:key="index" tappable @click="openChat(chat)">
        <div class="center">
          <div class="title" v-html="chat.chat_title"></div>
        </div>
        <div class="right">
          <div class="update_date" v-html="chat.date_updated_string"></div>
        </div>
      </v-ons-list-item>
      <v-ons-list-item v-if="closedChats.length == 0">
        <div class="left">Geen resultaten</div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list-item class="logout" tappable @click="logout()" v-bind:class="{ visible: isCustomersOnlyDienst}">
      <div class="center">Uitloggen</div>
    </v-ons-list-item>
  </v-ons-page>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ToolbarDefault from "@/components/ToolbarDefault";
import PageLoader from '@/components/PageLoader';
import DienstChat from '@/views/DienstChat';

import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';

export default {
  name: 'Dienst',
  props: ['saas_id'],
  data() {
    return {
      isLoading: true,
      item: false,
      intervalFetcher: false,
      isCustomersOnlyDienst: false,      
    }
  },
  components: { 
    ToolbarDefault,
    PageLoader,
  },
  beforeDestroy(){
    clearInterval(this.intervalFetcher);
  },
  mounted() {
    if(this.$route.query.is_customers_only_dienst !== undefined && this.$route.query.is_customers_only_dienst === 'true'){
      this.isCustomersOnlyDienst = true;
    }

    const showErrorToast = async () => {
      await Toast.show({
        text: 'Oeps, er is iets fout gegaan!',
      });
    };
    try {
      this.item = this.dienstItem(this.$route.params.saas_id);
      if (this.item && this.item.saas_id) {
        this.showChats(this.item.saas_id, true);
      } else {
        showErrorToast();
      }
    } catch (error) {
      showErrorToast();
    }
  },
  computed: {
    ...mapGetters('diensten', [
      'dienstItem',
    ]),
    chats() {
      return this.$store.state.dienst.chats;
    },
    activeChats() {
      return this.$store.state.dienst.chats.filter(chat => chat.status !== 'finished');
    },
    closedChats() {
      return this.$store.state.dienst.chats.filter(chat => chat.status === 'finished');
    },
  },
  methods: {
    ...mapActions('dienst', [
      'getChats',
    ]),
    ...mapActions('auth', {
      'logoutUser': 'logout'
    }),
    async logout(){
      let confirmRet = await Dialog.confirm({
        title: 'Uitloggen',
        message: 'Weet u zeker dat u wilt uitloggen?',
        PromptOptions: {
          okButtonTitle: "Uitloggen",
          cancelButtonTitle: "Annuleren",
        }
      });

      if (confirmRet.value) {
        this.logoutUser()
            .then(() => {
              this.$router.push('/login');

              Toast.show({
                text: 'U bent succesvol uitgelogd!',
                position: 'bottom',
              }).catch(error => console.error('logoutUser', error));
            });
      }
    },
    showChats(saas_id, startInterval){ 
        this.getChats(saas_id).then(()=>{
              this.isLoading = false;
              if(startInterval) this.intervalChats();
              }).catch(error => console.error('getChats', error));
    },
    intervalChats(){        
        let self = this; 
        this.intervalFetcher = setInterval(function(){
          self.showChats(self.item.saas_id, false);
        }, 5000);
    },
    openChat(chat){
        this.$eventListener.$emit('push-page', {
          ...DienstChat,
          onsNavigatorProps: {
            chat_id: chat.chat_id,
            saas_id: this.item.saas_id
          }
        });
      
    }
  }
}
</script>
<style lang="scss">
.chats {
  .chat {
    .list-item__left {

      .message_icon {
        font-size: 1.25rem;
        background:#efeff4;
        color: $yb_colorAccent;
        border-radius: 100%;
         width: 30px;
         height: 30px;
         text-align: center;
         line-height: 30px;

         &.new {
           background:$yb_colorYellow;
           color: $yb_colorBlack;
         }
      }

    }
    .list-item__center {
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .title {
      }

      .title_status {
        font-size: .75rem;
        color: $yb_colorAccent;
      }
    }

    .list-item__right {   
      text-align: right;   

      .update_date {
        position: absolute;
        font-size: .75rem;
        color: $yb_colorAccent;
        right: 15px;
      }
    }
  }
}
.logout {
  position: absolute !important;
  bottom: calc(1px + env(safe-area-inset-bottom));
  background: #FFF;
  color: $colorError !important;
  display: none !important;

  &.visible {
    display: flex !important;
  }

  .center {
    background-image: none !important;
  }
}
</style>