<template>
  <div id="page-loader">
    <div class="loader-holder">
      <div class="loader"></div>
      <div class="text">Aan het laden...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolbarDefault',
  props: ['title', 'toggleMenuAction'],
}
</script>
<style lang="scss">
#page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;

  .loader-holder {
    position: absolute;  
    top: 25vh;
    left: calc(50%);
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;


    .loader {
      border: 5px solid transparent;
      border-radius: 50%;
      border-top: 5px solid $yb_colorOrange;
      width: 45px;
      height: 45px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin-bottom: 1.5rem;
    }

  }

}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
