import Vue from 'vue'
import VueRouter from 'vue-router'
import Root from '@/views/Root';
import Login from "@/views/Login";
import DienstStack from "@/views/DienstStack";
import {store} from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    home: false
  },
  {
    path: '/',
    name: 'Root',
    component: Root,
    home: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dienst/:saas_id',
    name: 'Dienst',
    component: DienstStack,
    params: true,
    home: false,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.auth_key) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
});

export default router
