<template>
  <div v-html="message.html"></div>
</template>

<script>
export default {
  name: 'ChatMessage',
  props: ['message'],
}
</script>
