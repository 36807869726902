<template>
  <v-ons-toolbar id="toolbar-default">
    <div class="left">
      <v-ons-toolbar-button v-if="showBack" @click="goBack">
        <v-ons-icon icon="ion-ios-arrow-back, material:md-arrow-back"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>
    <div class="center">{{ title }}</div>
    <div class="right">
      <v-ons-toolbar-button v-if="showMenu" @click="toggleMenuAction">
        <v-ons-icon icon="ion-ios-menu, material:md-menu"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>
  </v-ons-toolbar>
</template>

<script>
export default {
  name: 'ToolbarDefault',
  props: {
    title: String,
    toggleMenuAction: Function,
    backButtonAction: Function,
    showBack: {type: Boolean, default: false},
    showMenu: {type: Boolean, default: true},
  },
  methods: {
    goBack(){
      if(this.backButtonAction == undefined){
        this.$router.go(-1);
      } else {
        this.backButtonAction();
      }
    }
  }
}
</script>
<style lang="scss">
#toolbar-default {
  background: $yb_colorYellow;

  .toolbar__right,
  .toolbar__left {
    width: 15%;
  }
  .toolbar__center {
    width: 70%;
  }

  .toolbar__title {
    color: $yb_colorBlack;
  }

  .toolbar-button {
    color: $yb_colorBlack;
  }
}
</style>
